import React from 'react';
import { Helmet } from 'react-helmet';
import { ThemeProvider } from 'styled-components';
import { guidelineColors } from './theme';
import AppRouter from './app.router';
import FooterComponent from './components/footer/footer.component';

const getTheme = () => guidelineColors;

const App = () => (
  <ThemeProvider theme={getTheme()}>
    <Helmet>
      <title>Pratiraksha 4Ever</title>
      <meta charSet="utf-8" />
      <meta name="description" content="An insurance brokerage website" />
    </Helmet>
    <AppRouter />
    <FooterComponent />
  </ThemeProvider>
);

export default App;
