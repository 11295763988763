import styled from 'styled-components';
import { deviceWidths } from '../../config/config';

export const HamburgerTheme = styled.div`
    display: flex;
    align-items: center;

    .hamburger-btn {
        cursor: pointer;
        background: ${(props) => props.theme.red};
        color: ${(props) => props.theme.white};
        height: 40px;

        svg{
            width: 40px;
            height: 35px;
        }

        &:focus {
            outline:  0;
        }
    }

    @media (max-width:${deviceWidths.phoneWidth}) {
        .hamburger-btn {
            cursor: pointer;
            background: ${(props) => props.theme.red};
            color: ${(props) => props.theme.white};
            height: 30px;

            svg{
                width: 24px;
                height: 24px;
            }
    
            &:focus {
                outline:  0;
            }
        }
    }
`;
