import React from 'react';
import { LogoWrapper } from './style';
import Pratiraksha4ever_logo from '../../assets/Pratiraksha4ever_logo.jpg'

const Logo = ({ handleLogoClick }) => (
  <LogoWrapper>
    <div className="logo-wrapper" onClick={() => handleLogoClick()}>
      <img src={Pratiraksha4ever_logo} alt="Pratiraksha 4Ever Logo" />
      <div className="logo-text">
        <div className="line1-text">Pratiraksha 4Ever</div>
        <div className="line2-text">Insurance Brokers Pvt Ltd</div>
      </div>

    </div>

  </LogoWrapper>
);

export default Logo;
