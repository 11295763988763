import styled from 'styled-components';
import { deviceWidths } from '../../config/config';

export const LogoWrapper = styled.div`
    display:flex;
    align-items: center;
    .logo-wrapper{
        display:flex;
        align-items: center;
        color: ${(props) => props.theme.red};
        text-decoration: none;
        cursor: pointer;
        img{
            width 90px;
            height: 90px;
            padding: 0 5px;
        }
        .logo-text{
            .line1-text{
                font-size: 19.5px;
                font-weight: 600;
            }
            .line2-text{
                font-size: 14px;
                font-weight: 500;
            }
        }

    }

    @media (max-width:${deviceWidths.phoneWidth}) {
        .logo-wrapper{
            img{
                width 60px;
                height: 60px;
                padding: 0 5px;
            }
            .logo-text{
                .line1-text{
                    font-size: 15px;
                }
                .line2-text{
                    font-size: 10.5px;
                }
            }
        }
    }
    
   
    
`;
