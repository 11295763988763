import styled from 'styled-components';
import { deviceWidths } from '../../config/config';

export const Footer = styled.footer`
    width: 100%;
    padding: 16px 200px;
    box-sizing: border-box;
    background:${(props) => props.theme.red};
    color: ${(props) => props.theme.white};
    display: flex;
    justify-content: space-between;
    align-items: center;
    .social-links {
        a {
            color: ${(props) => props.theme.white};
            margin: 0 8px;
            svg {
                width: 34px;
                height: 34px;
            }
        }
    }

    @media (max-width:${deviceWidths.smallDesktop}) {
        padding: 16px 60px;
    }

    @media (max-width:${deviceWidths.tabletLandscapeWidth}) {
        padding: 16px 40px;
    }

    @media (max-width:${deviceWidths.tabletWidth}) {
        font-size: 16px;
        flex-direction: column-reverse;
        justify-content: center;
        padding: 10px 0 5px;
        .copy-right {
            margin: 10px 0;
        }
        .social-links {
            a {
                margin: 0 10px;
                svg {
                    width: 30x;
                    height: 30px;
                }
            }
        }
    }

    @media (max-width:${deviceWidths.phoneWidth}) {
        font-size: 14px;
        padding: 10px;
        text-align: center;
        .social-links {
            a {
                svg {
                    width: 24px;
                    height: 24px;
                }
            }
        }
    }

`;
