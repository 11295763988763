import React from 'react';
// import FacebookIcon from '@material-ui/icons/Facebook';
// import InstagramIcon from '@material-ui/icons/Instagram';
// import TwitterIcon from '@material-ui/icons/Twitter';
// import LinkedInIcon from '@material-ui/icons/LinkedIn';
import { Footer } from './style';

const date = new Date();
const FooterComponent = () => (
  <Footer>
    <div className="copy-right">
      ©
      {' '}
      { date.getFullYear() }
      {' '}
      Pratiraksha 4Ever Insurance Brokers Pvt Ltd. All Rights Reserved.
    </div>
    {/* <div className="social-links">
                <a href="https://www.instagram.com/" target="_blank" rel="noopener noreferrer"><InstagramIcon></InstagramIcon></a>
                <a href="https://www.facebook.com/" target="_blank" rel="noopener noreferrer"><FacebookIcon></FacebookIcon></a>
                <a href="https://www.linkedin.com/" target="_blank" rel="noopener noreferrer"><LinkedInIcon></LinkedInIcon></a>
                <a href="https://twitter.com/" target="_blank" rel="noopener noreferrer"><TwitterIcon></TwitterIcon></a>
            </div> */}
  </Footer>
);

export default FooterComponent;
