import styled from 'styled-components';
import { deviceWidths } from '../../config/config';

export const HeaderWrapper = styled.header`
    width  :100%;
    height: 94px;
    display: flex;
    justify-content: space-between;
    padding: 1px 200px;
    box-sizing: border-box;
    border-bottom: 1px solid ${(props) => props.theme.red};
    border-width: medium;
    background-color: ${(props) => props.theme.white};

    @media (max-width:${deviceWidths.smallDesktop}) {
        padding: 1px 60px;
    }

    @media (max-width:${deviceWidths.tabletLandscapeWidth}) {
        padding: 1px 40px;
    }

    @media (max-width:${deviceWidths.phoneWidth}) {
        height: 84px;
        padding: 1px 15px;
    }
`;