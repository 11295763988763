import React, { Suspense, lazy } from 'react';
import {
  Router, Switch, Route, Redirect,
} from 'react-router-dom';
import { createBrowserHistory as createHistory } from 'history';
import LoadingComponent from './components/loading/loading-component';
import HeaderComponent from './components/header/header.component';
import { routesNav } from './config/config';
import { Card } from '@material-ui/core';

const Home = lazy(() => import('./pages/home/home.page'));
const Products = lazy(() => import('./pages/products/products.page'));
const ContactUs = lazy(() => import('./pages/contact-us/contact-us.page'));
const AboutUs = lazy(() => import('./pages/about-us/about-us.page'));

const history = createHistory();

const routeLabels = routesNav.map((item) => item.to);

const AppRouter = () => (
  <Router history={history}>
    <Suspense fallback={<LoadingComponent />}>
      <>
        <HeaderComponent />
        <section>
          <Card>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path={routeLabels[1]} component={AboutUs} />
              <Route exact path={routeLabels[2]} component={Products} />
              <Route exact path={routeLabels[3]} component={ContactUs} />
              <Redirect to="/" />
            </Switch>
          </Card>
        </section>
      </>
    </Suspense>
  </Router>
);

export default AppRouter;
