import React from 'react';
import {
  HomeOutlined, AddBoxOutlined, AirportShuttleOutlined, FlightTakeoffOutlined,
} from '@material-ui/icons';
import PersonIcon from '@material-ui/icons/Person';
import PeopleIcon from '@material-ui/icons/People';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import StarsIcon from '@material-ui/icons/Stars';
import PolicyIcon from '@material-ui/icons/Policy';
import CardMembershipIcon from '@material-ui/icons/CardMembership';
import ThumbUpAltIcon from '@material-ui/icons/ThumbUpAlt';
import Filter1Icon from '@material-ui/icons/Filter1';
import Filter2Icon from '@material-ui/icons/Filter2';
import Filter3Icon from '@material-ui/icons/Filter3';
import LifeInsurance from '../assets/carousel-images/Life-Insurance.jpg';
import HealthInsurance from '../assets/carousel-images/Health-Insurance.jpg';
import HomeInsurance from '../assets/carousel-images/Home-Insurance.jpg';
import MotorInsurance from '../assets/carousel-images/Motor-Insurance.jpg';
import TravelInsurance from '../assets/carousel-images/Travel-Insurance.jpg';
import KailashchandraSharmaImg from '../assets/profile-images/Kailashchandra_Sharma.jpg';
import AmitTakImg from '../assets/profile-images/Amit_Tak.jpg';

export const deviceWidths = {
  phoneWidth: '480px',
  tabletWidth: '768px',
  tabletLandscapeWidth: '1024px',
  smallDesktop: '1360px',
};

export const routesNav: Array<any> = [
  {
    to: '/',
    label: 'Home',
  },
  {
    to: '/about-us',
    label: 'About Us',
  },
  {
    to: '/products',
    label: 'Products',
  },
  {
    to: '/contact-us',
    label: 'Contact Us',
  },
];

export const carouselSettings = {
  arrows: true,
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 8000,
  prevArrow: null,
  nextArrow: null,
  beforeChange: () => ({}),
};

export const testimonialSettings = {
  arrows: true,
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: false,
  autoplaySpeed: 8000,
  prevArrow: null,
  nextArrow: null,
  beforeChange: () => ({}),
};

export const testimonialText: Array<object> = [{
  name: 'SAURABH SHAH',
  designation: 'HEAD-ADMIN',
  company: 'ERIS LIFE SCIENCE LTD',
  city: '',
  desc: `While interacting with Pratiraksha 4 Ever Insurance brokers Pvt ltd I found that this new organization is working for 
  customers and keeping ethics at every step of their process. I am delighted to discuss my insurance requirement and satisfied with their approach.`,
  starsCount: 5,
}, {
  name: 'BAL KISHAN SHARMA',
  designation: 'DIRECTOR',
  company: 'BANSIYA MINECHEM PVT LTD',
  city: 'AHMEDABAD',
  desc: `The people at Pratiraksha 4 Ever Insurance brokers Pvt ltd are very cordial and always ready to help me and my company and instantly 
  give resolutions to my insurance requirements. They are always ready to go out of the way to support.`,
  starsCount: 5,
}, {
  name: 'ROHIT PATEL',
  designation: 'DIRECTOR',
  company: 'SPECIFIC CERAAMICS LIMITED',
  city: 'GANDHINAGAR',
  desc: `Pratiraksha 4 Ever Insurance brokers Pvt ltd approached me for my insurance requirement and found them so diligent and they have 
  given me detailed solution and very happy with their prompt services.`,
  starsCount: 5,
}, {
  name: 'SUNIL SHARMA',
  designation: 'PARTNER',
  company: 'KIRAN BULK CARRIERS',
  city: 'JAIPUR',
  desc: `Pratiraksha 4 Ever Insurance brokers Pvt ltd is a new company but their experience is very rich in insurance. The kind of solution 
  they are providing are amazing and they are updated and techno savvy and latest in their insurance field.`,
  starsCount: 5,
}, {
  name: 'DHAWAL THAKKAR',
  designation: 'DIRECTOR',
  company: 'SAKAR GRANITO (INDIA) PVT. LIMITED',
  city: 'AHMEDABAD',
  desc: `I am delighted to discuss my insurance requirement with Pratiraksha 4 Ever Insurance brokers Pvt ltd and satisfied with their approach. 
  They have kept a customer centric approach throughout our interaction.`,
  starsCount: 5,
}, {
  name: 'DR. RAVIKESH YADAV',
  designation: 'VICE PRESIDENT',
  company: 'DAVA ONCOLOGY',
  city: '',
  desc: `The people at Pratiraksha 4 Ever Insurance brokers Pvt ltd are very cordial and always ready to help me. They have kept a customer 
  centric approach throughout our interaction. They suggested to me the insurance which is suitable for me and my family.`,
  starsCount: 5,
}];

export const carouselImages: Array<object> = [
  {
    image: LifeInsurance,
    desc: 'UNLESS YOU ARE IMMORTAL YOU NEED LIFE INSURANCE',
    color: '#FFFFFF',
    link: '/products',
  },
  {
    image: MotorInsurance,
    desc: 'YOU CAN\'T PREVENT THE ACCIDENT BUT YOU CAN BE PREPARED FOR ITS EXPENSES',
    color: '#FFFFFF',
    link: '/products',
  },
  {
    image: HealthInsurance,
    desc: 'IT IS NO COINCIDENCE THAT FOUR OF THE SIX LETTERS IN HEALTH ARE HEAL',
    color: '#FFFFFF',
    link: '/products',
  },
  {
    image: HomeInsurance,
    desc: 'HOME SHOULD BE AN ANCHOR , A PORT IN A STORM, A REFUGE A HAPPY PLACE TO DWELL AND MUST BE PROTECTED',
    color: '#FFFFFF',
    link: '/products',
  },
  {
    image: TravelInsurance,
    desc: 'TO TRAVEL IS TO EVOLVE AND WE PROTECT YOU TO KEEP YOU FREE TO EXPLORE YOURSELF',
    color: '#FFFFFF',
    link: '/products',
  },
];

export const infoStrap = [{
  icon: <PersonIcon fontSize="large" />,
  description: 'Life',
},
{
  icon: <AirportShuttleOutlined fontSize="large" />,
  description: 'Motor',
},
{
  icon: <AddBoxOutlined fontSize="large" />,
  description: 'Health',
},
{
  icon: <HomeOutlined fontSize="large" />,
  description: 'Home',
},
{
  icon: <FlightTakeoffOutlined fontSize="large" />,
  description: 'Travel',
}];

export const certificateIcons = ['CardMembershipIcon', 'ThumbUpAltIcon'];
export const certificateData = {
  CardMembershipIcon: {
    title: 'IRDA LICENSED COMPANY',
    content: ' We, PRATIRAKSHA4EVER, are one among the first in the country to be granted  licence by IRDA...',
    description: '',
  },
  ThumbUpAltIcon: {
    title: 'EXPERTISE',
    content: 'The team at PRATIRAKSHA4EVER has a strong track-record of handling large group insurance requirements ...',
    description: '',
  },
  iconComponents: {
    CardMembershipIcon,
    ThumbUpAltIcon,
  },
};

export const productsPageConfig = {
  productsContent: [
    {
      id: 1,
      icon: 'Filter1Icon',
      title: 'Explore a wide range of options',
      description: 'We provide insurance services of all kinds. Just contact us and we\'ll take things from there.',
    },
    {
      id: 2,
      icon: 'Filter2Icon',
      title: 'Choose the plan best for you',
      description: 'Meet our partners personally and select the best plans at the best available price in the market.',
    },
    {
      id: 3,
      icon: 'Filter3Icon',
      title: 'Leave the rest on us!',
      description: 'We keep proper transparency and our partners work relentlessly to give you the best experience!',
    },
  ],
  figuresData: [{
    name: 'customers',
    label: 'Happy customers',
  }, {
    name: 'companies',
    label: 'Companies on-boarded',
  },
  ],
  Components: {
    Filter1Icon,
    Filter2Icon,
    Filter3Icon,
  },
  ProductsTitle: {
    HEADER: 'Pratiraksha 4Ever is a one-stop solution for all your insurance needs',
    FIGURES: 'Pratiraksha 4Ever in figures',
  },
};

export const aboutUsPageConfig = {
  profileData: [{
    name: 'Mr. Amit Tak',
    description: `Amit is a visionary and seasoned professional with over 22 years of experience, spearheading
                  a gamut of banking and the BFSI vertical. A motivational management style with a proven 
                  record of building and retaining high-performance teams and prudent in formulating succession 
                  plans. Conversant in creating and administering the overall corporate strategy and be the 
                  primary go-to person for investments and risk management.`,
    image: AmitTakImg,
  }, {
    name: 'Mr. Kailashchandra Sharma ',
    description: `Kailash, an Indian Air Force veteran, is an effective communicator and has exceptional interpersonal and 
                  management skills. He has a rich experience of 36 years in human resource, industrial relationship, 
                  administration and statutory compliance. He also comes with diverse industry experience in Banking, 
                  Manufacturing, Textile, Transportation and Mines & Minerals.`,
    image: KailashchandraSharmaImg,
  }],
  MissionData: {
    content: 'To be the most trustworthy and dedicated company to serve with highest ethical standards and integrity.',
  },
  ValuesData: {
    content: `Pratiraksha 4Ever protects your life, business, health and everything important to you with a commitment of 
              a long-term relationship.`,
  },
  aboutUsContent: [
    {
      icon: '',
      title: 'Customers First',
      description: `We build products and services that our customers love. 
          We relentlessly work towards identifying the needs of our customers and provide the best possible experience.`,
    },
    {
      icon: '',
      title: 'Build and Honour Absolute Trust',
      description: `We highly value the trust our customers put in us. 
          The transparency and guidance we provide ensures that our customers can completely rely on us to secure their future.`,
    },
    {
      icon: '',
      title: 'Strive for Excellence',
      description: `We keep raising the bar so that our customers get the best, always. 
          We keep following the market trends and ensure we have a competitive edge, 
          while keeping ourselves updated on the new innovations happening in the insurance space.`,
    },
    {
      icon: '',
      title: 'Provide Complete Transparency',
      description: `It's common to get lost in the technicalities and terms involved in the insurance policies. 
          With our unparalleled expertise and experience, we provide services with complete transparency 
          to help our customers take informed decisions.`,
    },
  ],
  aboutUsIcons: [
    'PeopleIcon',
    'VerifiedUserIcon',
    'StarsIcon',
    'PolicyIcon',
  ],
  AboutUsTitle: {
    MISSION: 'Mission',
    VALUES: 'Values',
    ABOUT: 'About Our Founders',
  },
  Components: {
    PeopleIcon,
    VerifiedUserIcon,
    StarsIcon,
    PolicyIcon,
  },
};

export const contactUsPageConfig = {
  formSection: {
    formHeader: 'GET IN TOUCH',
    formDescription: "Fill out this quick form and we'll be in touch with lightening speed",
  },
  selfDetailsSection: {
    contactSection: {
      header: 'CONNECT WITH US',
      email: 'info@pratiraksha4ever.com',
      call: '+91 79 48004727',
    },
    addressSection: {
      header: 'Pratiraksha 4Ever Insurance Brokers Pvt Ltd',
      addressLine1: 'C-501, Titanium Square,',
      addressLine2: 'Thaltej Cross Road,',
      addressLine3: 'SG Highway, Ahmedabad , Gujarat',
      addressLine4: 'Pin: 380058',
    },
  },
};
