import React, { useState, useContext } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';
import { ThemeContext } from 'styled-components';
import { HamburgerTheme } from './style';

export default function SwipeableTemporaryDrawer({ value, handleChange, routes }: any) {
  const themeContext = useContext(ThemeContext);
  const useStyles = makeStyles({
    list: {
      width: 250,
    },
    fullList: {
      width: 'auto',
    },
    listItem: {
      color: themeContext.red,
      '&:hover, &.Mui-focusVisible': {
        background: themeContext.red,
        borderRadius: 5,
        color: themeContext.white,
      },
      textTransform: 'uppercase',
    },
    selected: {
      color: themeContext.white,
      background: themeContext.red,
    },
    root: {
      // '& .MuiDrawer-paperAnchorRight': {
      //   height: '70%',
      // },
    },
  });

  const classes = useStyles();
  const [drawerState, setDrawerState] = useState({
    isOpen: false,
  });

  const routeLabels = routes.map((item) => item.label);

  const toggleDrawer = (isOpen: boolean) => (event) => {
    if (event.key === 'Tab' || (event.key === 'Shift')) {
      return;
    }
    setDrawerState({ ...drawerState, isOpen });
  };

  const list = () => (
    <div
      className={clsx(classes.list, { [classes.fullList]: false })}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        {routeLabels.map((text, index) => (
          <ListItem
            className={clsx(classes.listItem, { [classes.selected]: value === index })}
            button
            key={text}
            onClick={(event) => handleChange(event, index)}
          >
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>
    </div>
  );

  return (
    <HamburgerTheme>
      <button
        className="hamburger-btn"
        type="button"
        aria-label="Hamburger Menu"
        onClick={toggleDrawer(true)}
      >
        <MenuIcon />
      </button>
      <SwipeableDrawer
        anchor="right"
        open={drawerState.isOpen}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        className={classes.root}
        transitionDuration={500}
      >
        {list()}
      </SwipeableDrawer>
    </HamburgerTheme>
  );
}
