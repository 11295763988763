import styled from 'styled-components';
import { deviceWidths } from '../../config/config';


export const TabTheme = styled.div`
    .MuiAppBar-colorPrimary {
        color: rgba(0, 0, 0, 0.87);
        background-color: ${(props) => props.theme.white};;
        font-size: 20px;
        box-shadow: none;
    }
    .MuiTabs-flexContainer {
        justify-content: flex-end;
        height: 90px;
       
        button{
            font-size: 16px;
            font-family: 'Inter', sans-serif;
        }
    }
    .Mui-selected {
        color: ${(props) => props.theme.red};
        font-weight: bold;
    }
    .MuiTab-wrapper::after {
        content: '';
        display: block;
        width: 0;
        height: 2px;
        transition: width .3s;
        background:${(props) => props.theme.red};
    }
    .MuiTab-wrapper:hover::after {
       width: 75%;
    }

    @media (max-width:${deviceWidths.tabletLandscapeWidth})  {
        .MuiTabs-flexContainer {
            button{
                min-width: 100px;
            }
        }
    }

`;
